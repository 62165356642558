  @media print {
    .noprint {
      display: none;
    }

    @page {
      size: A4;

      @top-left {
        content: element(headerLeft);
        border-bottom: 2px solid #434190;
      }

      @top-center {
        border-bottom: 2px solid #434190;
      }

      @top-right {
        content: element(headerRight);
        border-bottom: 2px solid #434190;
      }

      @bottom-right {
        content: element(footerRight);
        border-top: 2px solid #434190;
      }

      @bottom-center {
        content: counter(page) ' / ' counter(pages);
        border-top: 2px solid #434190;
        font-size: 8pt;
      }

      @bottom-left {
        content: element(footerLeft);
        border-top: 2px solid #434190;
      }
    }

    .headerLeft {
      position: running(headerLeft);
      font-size: 12pt;
    }

    .headerRight {
      position: running(headerRight);
      font-size: 8pt;
      font-style: italic;
      text-align: right;
      color: #667eea;
    }

    .footerLeft {
      position: running(footerLeft);
    }

    .footerLeft img {
      width: 20mm;
    }

    .footerRight {
      position: running(footerRight);
      text-align: right;
      font-size: 8pt;
    }

  }

  @media screen {
    .printOnly {
      display: none;
    }
  }